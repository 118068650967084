/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-05-29",
    versionChannel: "nightly",
    buildDate: "2024-05-29T00:06:26.712Z",
    commitHash: "1712cd61e50f75f2bf4b153b168ed05a0056fe36",
};
